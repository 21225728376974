export const createCommunityFieldsConfig = [
  {
    valuesKey: 'customerNumber',
    label: 'Customer Number',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.customerNumber && errors.customerNumber,
  },
  {
    valuesKey: 'supplierId',
    label: 'Supplier ID',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.supplierId && errors.supplierId,
  },
  {
    valuesKey: 'communityId',
    label: 'Community ID',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.communityId && errors.communityId,
  },
  {
    valuesKey: 'communityName',
    label: 'Community Name',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.communityName && errors.communityName,
  },
  {
    valuesKey: 'invoiceLevel',
    label: 'Invoicing level',
    type: 'select',
    disabled: values => !values.features.sharedUsersEnabled,
    options: [
      {
        label: 'Workspace',
        value: 'Workspace',
        disabled: values => !values.features.sharedUsersEnabled,
      },
      {
        label: 'Community',
        value: 'Community',
        disabled: values => !values.features.sharedUsersEnabled,
      },
    ],
    required: values => true,
    error: (touched, errors) => touched.invoiceLevel && errors.invoiceLevel,
  },
  {
    valuesKey: 'agreementId',
    label: 'Agreement ID',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.agreementId && errors.agreementId,
  },
  {
    valuesKey: 'project',
    label: 'Project',
    type: 'text',
    required: values => false,
    error: (touched, errors) => touched.project && errors.project,
  },
  {
    valuesKey: 'salesContactName',
    label: 'Sales contact name',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.salesContactName && errors.salesContactName,
  },
  {
    valuesKey: 'salesContactEmail',
    label: 'Sales contact email',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.salesContactEmail && errors.salesContactEmail,
  },
  {
    valuesKey: 'customerContactName',
    label: 'Customer contact name',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.customerContactName && errors.customerContactName,
  },
  {
    valuesKey: 'customerContactEmail',
    label: 'Customer contact email',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.customerContactEmail && errors.customerContactEmail,
  },
  {
    valuesKey: 'password',
    label: 'Community Admin Password',
    type: 'text',
    required: values => !values.communityId,
    error: (touched, errors) => touched.password && errors.password,
  },
  {
    valuesKey: 'confirmPassword',
    label: 'Repeat Password',
    type: 'text',
    required: values => !values.communityId,
    error: (touched, errors) =>
      touched.confirmPassword && errors.confirmPassword,
  },
  {
    valuesKey: 'secret',
    label: 'Community Secret',
    type: 'text',
    required: values => !values.secret,
    error: (touched, errors) => touched.secret && errors.secret,
  },
  {
    valuesKey: 'confirmSecret',
    label: 'Repeat Secret',
    type: 'text',
    error: (touched, errors) => touched.confirmSecret && errors.confirmSecret,
  },
  {
    valuesKey: 'tenantStore',
    type: 'select',
    custom: true,
    label: 'Community storage',
    error: (touched, errors) => touched.tenantStore && errors.tenantStore,
  },
];

export const updateCommunityFieldsConfig = [
  {
    valuesKey: 'customerNumber',
    label: 'Customer Number',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.customerNumber && errors.customerNumber,
  },
  {
    valuesKey: 'supplierId',
    label: 'Supplier ID',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.supplierId && errors.supplierId,
  },
  {
    valuesKey: 'communityId',
    label: 'Community ID',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.communityId && errors.communityId,
    disabled: () => true,
  },
  {
    valuesKey: 'communityName',
    label: 'Community Name',
    type: 'text',
    required: values => true,
    error: (touched, errors) => touched.communityName && errors.communityName,
  },
  {
    valuesKey: 'invoiceLevel',
    label: 'Invoicing level',
    type: 'select',
    disabled: values => !values.features.sharedUsersEnabled,
    options: [
      {
        label: 'Workspace',
        value: 'Workspace',
        disabled: values => !values.features.sharedUsersEnabled,
      },
      {
        label: 'Community',
        value: 'Community',
        disabled: values => !values.features.sharedUsersEnabled,
      },
    ],
    required: values => true,
    error: (touched, errors) => touched.invoiceLevel && errors.invoiceLevel,
  },
  {
    valuesKey: 'agreementId',
    label: 'Agreement ID',
    type: 'text',
    required: values => values.invoiceLevel === 'Community',
    error: (touched, errors) => touched.agreementId && errors.agreementId,
  },
  {
    valuesKey: 'project',
    label: 'Project',
    type: 'text',
    required: values => false,
    error: (touched, errors) => touched.project && errors.project,
  },
  {
    valuesKey: 'salesContactName',
    label: 'Sales contact name',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.salesContactName && errors.salesContactName,
  },
  {
    valuesKey: 'salesContactEmail',
    label: 'Sales contact email',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.salesContactEmail && errors.salesContactEmail,
  },
  {
    valuesKey: 'customerContactName',
    label: 'Customer contact name',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.customerContactName && errors.customerContactName,
  },
  {
    valuesKey: 'customerContactEmail',
    label: 'Customer contact email',
    type: 'text',
    required: values => false,
    error: (touched, errors) =>
      touched.customerContactEmail && errors.customerContactEmail,
  },
];

export const createCommunityFeaturesFieldsConfig = [
  {
    label: 'Allow room creation',
    valuesKey: 'createRoomEnabled',
    tooltip: '',
  },
  {
    label: 'Allow room order',
    valuesKey: 'orderRoomEnabled',
    tooltip: '',
  },
  {
    label: 'Automatic entry ID',
    valuesKey: 'generatorEnabled',
    tooltip: '',
  },
  {
    label: 'Distribution groups',
    valuesKey: 'communityGroupEnabled',
    tooltip: '',
  },
  {
    label: 'Drawing management',
    valuesKey: 'drawingManagementEnabled',
    tooltip: '',
  },
  {
    label: 'Interaxo onSite mobile',
    valuesKey: 'onSiteEnabled',
    tooltip: '',
  },
  {
    label: 'Internal comments',
    valuesKey: 'internalCommentsEnabled',
    tooltip: '',
  },
  {
    label: 'Multifactor authentication',
    valuesKey: 'mfaRequired',
    tooltip: '',
  },
  {
    label: 'Oculo Integration',
    valuesKey: 'oculoIntegrationEnabled',
    tooltip: '',
  },
  {
    label: 'Project context',
    valuesKey: 'projectContextEnabled',
    tooltip: '',
  },
  {
    label: 'Public links',
    valuesKey: 'publicLinksEnabled',
    tooltip: '',
  },
  {
    label: 'Publish to separate folder',
    valuesKey: 'externalPublishEnabled',
    tooltip: '',
  },
  {
    label: 'Shared users',
    valuesKey: 'sharedUsersEnabled',
    tooltip: '',
    onChange: function(setFieldValue, values) {
      return function() {
        const sharedUsersEnabled = arguments[1];

        if (sharedUsersEnabled) {
          setFieldValue('invoiceLevel', 'Community');
        } else {
          setFieldValue('invoiceLevel', 'Workspace');
        }

        setFieldValue('features.sharedUsersEnabled', sharedUsersEnabled);
      };
    },
  },
  {
    label: 'Visual boards',
    valuesKey: 'visualBoardsEnabled',
    tooltip: '',
  },
  {
    label: 'Workspaces',
    valuesKey: 'workspacesEnabled',
    tooltip: '',
  },
];

export const updateCommunityFeaturesFieldsConfig = [
  {
    label: 'Allow room creation',
    valuesKey: 'createRoomEnabled',
    tooltip: '',
  },
  {
    label: 'Allow room order',
    valuesKey: 'orderRoomEnabled',
    tooltip: '',
  },
  {
    label: 'Automatic entry ID',
    valuesKey: 'generatorEnabled',
    tooltip: '',
  },
  {
    label: 'Distribution groups',
    valuesKey: 'communityGroupEnabled',
    tooltip: '',
  },
  {
    label: 'Drawing management',
    valuesKey: 'drawingManagementEnabled',
    tooltip: '',
  },
  {
    label: 'Interaxo onSite mobile',
    valuesKey: 'onSiteEnabled',
    tooltip: '',
  },
  {
    label: 'Internal comments',
    valuesKey: 'internalCommentsEnabled',
    tooltip: '',
  },
  {
    label: 'Multifactor authentication',
    valuesKey: 'mfaRequired',
    tooltip: '',
  },
  {
    label: 'Oculo Integration',
    valuesKey: 'oculoIntegrationEnabled',
    tooltip: '',
  },
  {
    label: 'Project context',
    valuesKey: 'projectContextEnabled',
    tooltip: '',
  },
  {
    label: 'Public links',
    valuesKey: 'publicLinksEnabled',
    tooltip: '',
  },
  {
    label: 'Publish to separate folder',
    valuesKey: 'externalPublishEnabled',
    tooltip: '',
  },
  {
    label: 'Shared users',
    valuesKey: 'sharedUsersEnabled',
    tooltip: '',
    onChange: function(setFieldValue, values) {
      return function() {
        const sharedUsersEnabled = arguments[1];

        if (!sharedUsersEnabled) {
          setFieldValue('invoiceLevel', 'Workspace');
        }

        setFieldValue('features.sharedUsersEnabled', sharedUsersEnabled);
      };
    },
  },
  {
    label: 'Visual boards',
    valuesKey: 'visualBoardsEnabled',
    tooltip: '',
  },
  {
    label: 'Workspaces',
    valuesKey: 'workspacesEnabled',
    tooltip: '',
  },
];
